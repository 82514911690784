import {
  InternalServerError,
  wrapErrorAsColumnError
} from '../errors/ColumnErrors';
import { safeGetModelArrayFromQuery } from '../model/getModel';
import { PackageModel } from '../model/objects/packageModel';
import { EFirebaseContext, EOrganization, ERef, Package } from '../types';
import { ResponseOrError, wrapSuccess } from '../types/responses';
import { BaseService } from './baseService';

export class PackageService extends BaseService<Package> {
  constructor(ctx: EFirebaseContext) {
    super(ctx, ctx.packagesRef());
  }

  public async getPublisherPackagesByFilingTypeLabel({
    publisherOrganization,
    filingTypeLabel,
    isUserPublisher
  }: {
    publisherOrganization: ERef<EOrganization>;
    filingTypeLabel: string;
    isUserPublisher: boolean;
  }): Promise<ResponseOrError<PackageModel[]>> {
    const query = this.ctx
      .packagesRef()
      .where('authorizedOrganization', '==', publisherOrganization)
      .where('filingTypeLabel', '==', filingTypeLabel);

    const [error, packages] = await safeGetModelArrayFromQuery(
      PackageModel,
      this.ctx,
      query
    );

    if (error) {
      return wrapErrorAsColumnError(error, InternalServerError);
    }

    if (packages.length === 0) {
      return wrapSuccess([]);
    }

    const visiblePackages = packages.filter(packageModel =>
      packageModel.isVisibleToUser(isUserPublisher)
    );

    return wrapSuccess(visiblePackages);
  }
}
