import { ColumnService } from '../services/directory';
import { ResponseError, wrapError } from '../types/responses';

type ColumnErrorOptions = {
  /**
   * Overrides the service that will be logged for this error
   */
  alertServiceOverride?: ColumnService;

  /**
   * The original error that caused this error
   * Is remapped as 'cause' in ErrorOptions and the string is extracted
   * to be included in the error message
   */
  originalError?: Error;
};

export abstract class ColumnError extends Error {
  static isColumnError = (error: Error): error is ColumnError => {
    return !!(error as ColumnError).status;
  };

  public alertServiceOverride?: ColumnService;

  constructor(
    public status: number,
    message: string,
    columnErrorOptions?: ColumnErrorOptions
  ) {
    const { alertServiceOverride, originalError } = columnErrorOptions || {};
    let combinedMessage = message;
    if (originalError) {
      combinedMessage = `${message} Original error: ${originalError.message}`;
    }
    // Options for error.d.ts
    const errorOptions = { cause: originalError } as ErrorOptions;
    super(combinedMessage, errorOptions);

    this.alertServiceOverride = alertServiceOverride;
  }
}

export class BadRequestError extends ColumnError {
  static status = 400;

  static isBadRequestError = (error: Error): error is BadRequestError => {
    return (
      ColumnError.isColumnError(error) &&
      error.status === BadRequestError.status
    );
  };

  constructor(message: string, options?: ColumnErrorOptions) {
    super(BadRequestError.status, message, options);
  }
}

export class UnauthorizedError extends ColumnError {
  static status = 401;

  static isUnauthorizedError = (error: Error): error is UnauthorizedError => {
    return (
      ColumnError.isColumnError(error) &&
      error.status === UnauthorizedError.status
    );
  };

  constructor(message: string, options?: ColumnErrorOptions) {
    super(UnauthorizedError.status, message, options);
  }
}

export class ForbiddenError extends ColumnError {
  static status = 403;

  static isForbiddenError = (error: Error): error is ForbiddenError => {
    return (
      ColumnError.isColumnError(error) && error.status === ForbiddenError.status
    );
  };

  constructor(message: string, options?: ColumnErrorOptions) {
    super(ForbiddenError.status, message, options);
  }
}

export class NotFoundError extends ColumnError {
  static status = 404;

  static isNotFoundError = (error: Error): error is NotFoundError => {
    return (
      ColumnError.isColumnError(error) && error.status === NotFoundError.status
    );
  };

  constructor(message: string, options?: ColumnErrorOptions) {
    super(NotFoundError.status, message, options);
  }
}

export class TimeoutError extends ColumnError {
  static status = 408;

  static isTimeoutError = (error: Error): error is TimeoutError => {
    return (
      ColumnError.isColumnError(error) && error.status === TimeoutError.status
    );
  };

  constructor(message: string, options?: ColumnErrorOptions) {
    super(TimeoutError.status, message, options);
  }
}

export class TeapotError extends ColumnError {
  static status = 418;

  static isTeapotError = (error: Error): error is TeapotError => {
    return (
      ColumnError.isColumnError(error) && error.status === TeapotError.status
    );
  };

  constructor(message: string, options?: ColumnErrorOptions) {
    super(TeapotError.status, message, options);
  }
}

export class TooManyRequestsError extends ColumnError {
  static status = 429;

  static isTooManyRequestsError = (
    error: Error
  ): error is TooManyRequestsError => {
    return (
      ColumnError.isColumnError(error) &&
      error.status === TooManyRequestsError.status
    );
  };

  constructor(message: string, options?: ColumnErrorOptions) {
    super(TooManyRequestsError.status, message, options);
  }
}

export class InternalServerError extends ColumnError {
  static status = 500;

  static isInternalServerError = (
    error: Error
  ): error is InternalServerError => {
    return (
      ColumnError.isColumnError(error) &&
      error.status === InternalServerError.status
    );
  };

  constructor(message: string, options?: ColumnErrorOptions) {
    super(InternalServerError.status, message, options);
  }
}

export type NewspaperErrorInfo = {
  newspaperId: string;
  newspaperName: string;
  parentNewspaperName: string | undefined;
};

/**
 * Configuration error that should be handled by CSMs
 * Overrides the specified alert service to be CSMs unless otherwise specified
 * WARNING: Must include 'To fix:' instructions in message!
 */
export class ConfigurationError extends ColumnError {
  // 520 is a custom status code for configuration errors
  static status = 520;

  static isConfigurationError = (error: Error): error is ConfigurationError => {
    return (
      ColumnError.isColumnError(error) &&
      error.status === ConfigurationError.status
    );
  };

  constructor({
    message,
    toFix,
    newspaperInfo,
    options
  }: {
    message: string;
    toFix: string;
    newspaperInfo: NewspaperErrorInfo;
    options?: ColumnErrorOptions;
  }) {
    const { newspaperId, newspaperName, parentNewspaperName } = newspaperInfo;
    const newspaperString = `paper '${newspaperName}', id ${newspaperId}, ${
      parentNewspaperName ?? ` parent '${parentNewspaperName}',`
    }`;
    const combinedMessage = `For ${newspaperString}: ${message} To fix: ${toFix}`;
    super(ConfigurationError.status, combinedMessage, options);
    this.alertServiceOverride =
      options?.alertServiceOverride ?? ColumnService.CSMS;
  }
}

type ColumnErrorConstructor = new (
  message: string,
  options?: ColumnErrorOptions
) => ColumnError;

export const wrapErrorAsColumnError = (
  error: Error,
  ErrorClass: ColumnErrorConstructor = InternalServerError
): ResponseError<ColumnError> => {
  if (ColumnError.isColumnError(error)) {
    return wrapError(error);
  }

  return wrapError(new ErrorClass(error.message, { originalError: error }));
};
